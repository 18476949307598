/*
Theme Name: MediaVax
Author: Code Greene
*/

/* STYLE RESET ========================================================================================================================== */
* { box-sizing: border-box;}
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video{margin:0; padding:0; border:0; outline:0; font-family:'Roboto', sans-serif; font-weight:300; vertical-align:baseline; background:transparent;}
svg text {letter-spacing:1px;font-family:'Roboto', sans-serif; font-weight:300;}
body{font-size:17px; line-height:1;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{display:block;}

figcaption{line-height:1.5;}

button, input, select, textarea{-webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box; margin:0; vertical-align:baseline;}
button, input{line-height:normal;}
input, textarea{background-image:-webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0)); /* Removing the inner shadow, rounded corners on iOS inputs */}
textarea{overflow:auto; vertical-align:top;}

input[type="checkbox"],
input[type="radio"]{padding:0;}
input[type="checkbox"]{-webkit-appearance:checkbox;}
input[type="radio"]{-webkit-appearance:radio;}
input[type="search"]{-webkit-appearance:textfield;}
input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}

select{-webkit-appearance:none; -moz-appearance:none; appearance:none;}

button, html input[type="button"],
input[type="reset"], input[type="submit"]{-webkit-appearance:button; cursor:pointer;}
button[disabled], input[disabled]{cursor:default;}

input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
      }


button::-moz-focus-inner,
input::-moz-focus-inner{border:0; padding:0;}

nav ul{list-style:none;}

blockquote, q{quotes:none;}

blockquote:before, blockquote:after,
q:before, q:after{content:''; content:none;}

strong,
strong *{font-weight:500;}
/* Changed from 700 to 500 for the "Overall Score" text on the results page */

ins{color:#000; background-color:#ff9; text-decoration:none;}

mark{color:#000; background-color:#ff9; font-style:italic; font-weight:700;}

del{text-decoration:line-through;}

abbr[title], dfn[title]{border-bottom:1px dotted; cursor:help;}

table{border-collapse:collapse; border-spacing:0;}

hr{display:block; height:1px; padding:0; margin:1em 0; border:0; border-top:1px solid #cccccc;}

iframe{max-width:100%;}

/* BLANKET STYLES ========================================================================================================================== */

::selection,
::-moz-selection{background:#037FAD; color:#fff; text-shadow:none;}

.clearfix:before,
.clearfix:after{display:table; clear:both; content:'';}
.clearfix{*zoom:1;}

.alignright{float:right;}
.alignleft{float:left;}
.aligncenter{display:block; margin:0 auto;}
img.alignright{margin-left:10px;}
img.alignleft{margin-right:10px;}

img{max-width:100%; -ms-interpolation-mode:bicubic; border:0;}

/* Text ========================================== */
a{padding:0; margin:0; background:transparent; vertical-align:baseline; text-decoration:none; transition:all .25s;}
a:hover,
a:focus,
a:active{cursor:pointer;}
p{margin:0 0 8px; line-height:1.3;}
p a{color:#36424B; text-decoration:underline;}
p a:hover,
p a:focus,
p a:active{text-decoration:underline;}
p img{max-width:100%; height:auto;}
p:last-child{margin-bottom:0 !important;}

h1, h1 *,
h2, h2 *,
h3, h3 *,
h4, h4 *,
h5, h5 *,
h6, h6 *{font-weight:500; line-height:1.3; transition:all 0.25s;}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a{color:#36424B;}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover{text-decoration:underline;}

h1{margin:0 0 20px; font-size:40px;}
h2{margin:0 0 17px; font-size:36px;}
h3{margin:0 0 14px; font-size:32px;}
h4{margin:0 0 11px; font-size:28px;}
h5{margin:0 0 8px; font-size:24px;}
h6{margin:0 0 5px; font-size:20px;}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child{margin-bottom:0 !important;}

ul,
ol{float:left; width:100%; padding-left:10px; box-sizing:border-box; margin:0 0 15px 15px; line-height:1.3;}
ul:last-child,
ol:last-child{margin-bottom:0 !important;}

ul{list-style:disc;}
ol{list-style:decimal;}
li{margin:0 0 0 5px;}
li:last-of-type{margin-bottom:0;}
li a{color:#36424B; text-decoration:underline;}
li a:hover,
li a:focus,
li a:active{text-decoration:underline;}
li img{height:auto;}

.white-text,
.white-text *{color:#fff;}
.black-text,
.black-text *{color:#000;}
.dark-text,
.dark-text *{color:#36424B;}

.text h1:last-child,
.text h2:last-child,
.text h3:last-child,
.text h4:last-child,
.text h5:last-child,
.text h6:last-child{margin-bottom:0;}
.text li{line-height:1.3;}

.wp-caption{float:left; max-width:100%; margin:0 10px; background-color:#fff;}
.wp-caption img{width:100%; height:auto;}
.wp-caption p{padding:5px; box-sizing:border-box;}

@media screen and (max-width:480px) {
	p img{width:100%; margin:10px 0;}
	.wp-caption{width:100% !important; margin:10px 0;}
}

blockquote{border-left:15px solid #037FAD; margin:30px 0; padding:5px 0 8px 20px; }
blockquote *{margin:0; line-height:1em;}
blockquote a:hover{text-decoration:underline;}
blockquote.twitter-tweet { border-left: none; margin: 0; padding: 0; }
blockquote.twitter-tweet img { height: 90px;}
/* Tables ========================================== */
table{float:left; width:100%; letter-spacing:1px;}
tr{background:#fff;}
tr:nth-child(even){background-color:#f2f2f2;}
th{padding:5px; color:#fff; background-color:#36424B; font-weight:400;}
td{padding:10px 5px; color:#777; font-size:14px;}
td a{color:#36424B; text-decoration:underline;}
td a:hover,
td a:focus,
td a:active{text-decoration:underline;}

/* Buttons ========================================== */
.btn a,
button,
a.btn,
.btn span,
span.btn,
.btn input[type=submit],
input[type=submit].btn{display:inline-block; padding:7px 20px !important; box-sizing:border-box; font-size:19px; font-weight:400; line-height:1; text-align:center; text-decoration:none; border:none; border-radius:7px; transition:all 0.25s;}
.btn a:hover,
a.btn:hover,
.btn span:hover,
span.btn:hover,
.btn input[type=submit]:hover,
input[type=submit].btn:hover{cursor:pointer;}

.green-btn a,
button.green-btn,
a.green-btn,
.green-btn span,
span.green-btn,
.green-btn input[type=submit],
input[type=submit].green-btn{color:#fff !important; background-color:#42B860;}
.green-btn a:hover,
a.green-btn:hover,
.green-btn span:hover,
span.green-btn:hover,
.green-btn input[type=submit]:hover,
input[type=submit].green-btn:hover{}

/* Sticky Footer ========================================== */
html, body{height:100%;}
.page-wrapper{display:flex; flex-direction:column; align-items:center; overflow-x:hidden; width:100%; min-height:100vh; margin:0 auto 150px; background-color:#b0b9c1; position:relative;
}
.page-content{flex-grow:1; display:flex; flex-direction:column; align-items:center; float:left; width:100%; padding:20px; box-sizing:border-box; position:relative;}

.mobile-container{display:flex; flex-wrap:wrap; justify-content:center; width:100%; max-width:335px; margin:0 auto; position:relative;}

.hide{display:none !important;}

section{float:left; width:100%; max-width:335px; padding:0; margin:0; position:relative;}
.section-text-main p,
.section-text-main li{font-size:20px; line-height:1.2;}

.overlay{position:absolute; top:0; right:0; bottom:0; left:0; z-index:1;}

/* CUSTOM STYLES =========================================================================================================================== */

/* Header ========================================== */
body.page-template-page-profile header,
body.page-template-page-history header,
body.page-template-page-faq header{display:none;}

header{display: flex; justify-content:center; align-items:center; float:left; width:100%; padding:20px 0; box-sizing:border-box; background-color:#36424B; position:relative; z-index:100;}

header .mobile-container{align-items:center;}

header .header-logo img{display:block; width:initial; max-width:102px; height:auto;}
header .header-logo img.logo2{display:block; max-height:70px; margin:-5px 0;}

header .header-menu{display:flex; justify-content:center; align-items:center; width:37px; height:37px; margin-left:auto; background-color:#657785; font-size:12px; font-weight:400; border-radius:50%;}
header .header-menu:hover{cursor:pointer;}

.back-link{display:flex; justify-content:center; float:left; width:calc(100% + 40px); padding:29px 0; box-sizing:border-box; margin:-20px 0 15px; background-color:#36424B;}
.back-link a{opacity:0.5; width:100%; max-width:375px; padding:0 20px; box-sizing:border-box; font-size:18px; font-weight:300;}
.back-link a:before{content:'\f053'; font-family:'Font Awesome 5 Free'; font-weight:700; margin-right:15px;}

/* Footer ========================================== */
body.home footer,
body.page-template-page-register footer{margin-top:20px;}
body.home footer .footer-feedback,
body.page-template-page-register footer .footer-feedback{display:none;}

footer{ width:100%; padding:20px 0; box-sizing:border-box; margin-top:50px; background-color:#36424B; position:fixed; bottom: 0;; z-index:99;}

footer .footer-feedback{display:flex; justify-content:center; align-items:center; width:100%; position:absolute; top:-60px;}
footer .footer-feedback img{width:20px; height:20px; margin-right:10px;}
footer .footer-feedback span{font-size:13px;}

footer .footer-bottom{opacity:0.5; font-size:9px;}
footer .footer-bottom p,
footer .footer-bottom li,
footer .footer-bottom a{margin-bottom:10px; font-size:9px; font-weight:500; text-align:center;}

/* Landing ========================================== */
section.landing{flex-grow:1; display:flex; flex-direction:column; justify-content:center;}
section.landing .section-block{float:left; width:100%; margin-bottom:50px;}
section.landing .section-block + .section-block{margin-bottom:20px;}
section.landing .section-block p{text-align:center;}
section.landing a.red-circle{width:60px; height:60px; margin:0 auto; box-sizing:border-box; margin-bottom:100px; border:5px solid #FF0000; border-radius:50%;}

/* Entry Form ========================================== */
section.entry-form{flex-grow:1; display:flex; flex-direction:column; margin-top:50px;}
section.entry-form a.prev-arrow{margin:-50px 0 32px;}
section.entry-form{float:left; width:100%; margin-bottom:30px;}
section.entry-form .form-page{display:flex; flex-direction:column; float:left; width:100%; position:relative;}
section.entry-form h5{margin-bottom:50px; font-weight:500;}
section.entry-form .form-input{float:left; width:100%; margin-bottom:20px;}
section.entry-form .form-input input,
section.entry-form .form-input select,
section.entry-form .form-input textarea{float:left; width:100%; padding:10px 0; box-sizing:border-box; color:#36424B; background:none !important; font-size:15px; outline:none !important; border:none !important; border-bottom:1px solid #36424B !important;}
section.entry-form .form-input input.empty,
section.entry-form .form-input select.empty,
section.entry-form .form-input textarea.empty{border-bottom:1px solid #ff0000 !important;}
section.entry-form .form-input.password { position:relative; top:0;left:0; float: left; width:100%;}
section.entry-form .form-input.password .icon { position: absolute; right: 5px; top:10px;}
section.entry-form .form-submit{margin:0 auto; width:100%; display: flex; flex-direction:column;}
section.entry-form button.btn{margin:15px auto; background-color: none;}
section.entry-form .FormInput { position: relative !important; top: 0; left: 0; float:left; width:100%;}
section.entry-form .FormInput > .FormValidComment { position: absolute; height: auto; bottom: 110%; right: 10px; max-width: 300px; border: #787979 1px solid;
    background: #dfdfdf;
    padding: 5px;
    color: #333;
    font-size: 0.8em;
    text-align: left;line-height: 1.5em;
    font-weight: 300; border-radius: 5px;
    box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
}
    section.entry-form .FormInput .FormValidComment div { color: #36424B;}

section.entry-form .FormInput .FormValidComment:after {content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid #787979;
    border-top: 6px solid #787979;
    border-bottom: 6px solid transparent;
    right: 20px;
    bottom: -12px;}

section.entry-form .FormInput .FormPasswordStrength .check { color: #b3b4b4;}
section.entry-form .FormInput .FormPasswordStrength .uncheck { color: #333;}

section.entry-form .message { background: #eceeee; width: 100%; padding: 10px 30px; border: #b3b4b4 1px solid; border-left: 5px solid #36424B; }
section.entry-form .message.signupError { border-left: 5px solid #f82f2f; }
.tab.show { display: block;}
.tab.hide { display: none;}

.bottom-links{display:flex; justify-content:space-between;}
.bottom-links a{font-size:15px; text-decoration:underline;}

section.entry-form .form-input input::-webkit-input-placeholder{font-size:15px; color:#36424B;}
section.entry-form .form-input input::-moz-placeholder{font-size:15px; color:#36424B;}
section.entry-form .form-input input:-ms-input-placeholder{font-size:15px; color:#36424B;}
section.entry-form .form-input input:-moz-placeholder{font-size:15px; color:#36424B;}
section.entry-form .form-input textarea::-webkit-input-placeholder{font-size:15px; color:#36424B;}
section.entry-form .form-input textarea::-moz-placeholder{font-size:15px; color:#36424B;}
section.entry-form .form-input textarea:-ms-input-placeholder{font-size:15px; color:#36424B;}
section.entry-form .form-input textarea:-moz-placeholder{font-size:15px; color:#36424B;}

section.entry-form .dots{display:flex; justify-content:center; align-items:center; float:left; width:100%; margin-top:13px;}
section.entry-form .dots span.dot{width:10px; height:10px; margin:7px; background-color:#35414A; border-radius:10px;}
section.entry-form #tab1 span.dot1{background-color:#fff;}
section.entry-form #tab2 span.dot2{background-color:#fff;}
section.entry-form #tab3 span.dot3{background-color:#fff;}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #b0b9c1 inset !important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #36424B !important;
}

input:focus,
input:active{outline:none;}

/* Profile ========================================== */
section.profile{display:flex; flex-direction:column;}
section.profile .section-block{display:flex; flex-direction:column; width:100%; padding:30px 20px; box-sizing:border-box; margin-bottom:5px; background-color:#657785; border-radius:3px;}
section.profile .section-block.block-links{padding:70px;}
section.profile .section-part{display:flex; align-items:flex-start; width:100%;}
section.profile .section-circle{display:flex; justify-content:center; align-items:center; width:80px; height:80px; margin-right:15px; background-color:#566470; font-size:24px; font-weight:300; border-radius:50%;}
section.profile .section-info{flex-grow:1; display:flex; flex-direction:column;}
section.profile .section-name{width:100%; margin-bottom:10px; font-size:23px; font-weight:300;}
section.profile .section-company{width:100%; margin-bottom:10px; font-size:13px;}
section.profile .section-position{width:100%; font-size:13px;}
section.profile a{display:flex; align-items:center; font-size:21px; margin-bottom:25px;}
section.profile a:last-of-type{margin-top:0;}
section.profile form a{float:left; width:100%; margin:0;}
section.profile a span{display:flex; justify-content:flex-end; align-items:center; flex:0 0 25px; height:25px; margin-right:15px;}
section.profile a span img{width:initial; max-width:100%; height:auto; max-height:100%;}

/* History ========================================== */
section.history{display:flex; flex-direction:column;}
section.history .section-block{display:flex; width:100%; padding:10px; box-sizing:border-box; margin-bottom:5px; background-color:#657785; border-radius:3px;}
section.history .rating{flex: 0 0 110px; display:flex; flex-direction:column; margin-right:10px;}
section.history .rating .number{display:flex; justify-content:center; width:100%; padding:10px 0; box-sizing:border-box; margin-bottom:2px; font-size:50px; text-align:center; border-radius:3px 3px 0 0;}
section.history .rating .date-time{width:100%; padding:5px 10px; box-sizing:border-box; font-size:11px; font-weight:400; text-align:center; border-radius:0 0 3px 3px;}
section.history .rating .date-time span{opacity:0.5;}
section.history a.preview{width:100%; position:relative;}
section.history a.preview .tweet{height:100%; background-color:#fff; border-radius:3px;}
section.history a.preview .more-info{display:flex; width:100%; padding:5px 12px; box-sizing:border-box; background-color:rgba(101,119,133,0.9); border-radius:0 0 3px 3px; position:absolute; bottom:0; z-index:2;}
section.history a.preview .more-info span{display:flex; align-items:center; opacity:0.5; font-size:10px; font-weight:400;}
section.history a.preview .more-info img{width:12px; height:12px; margin-right:10px;}
section.history .twitter_wrap {overflow:hidden; float:left; width:100%; height: 93px; max-height:93px; padding-bottom:50px; box-sizing:border-box; margin-bottom:5px; background:#fff; border-radius:0 0 3px 3px; position:relative; transition:all 0.5s;}
section.history .twitter_wrap .twitter-tweet{margin:0 !important;}
section.history .twitter_wrap .more-info{display:flex; width:100%; padding:5px 12px; box-sizing:border-box; background-color:rgba(101,119,133,1); border-radius:0 0 3px 3px; position:absolute; bottom:0; z-index:2;}
section.history .twitter_wrap .more-info span{display:flex; align-items:center; opacity:0.5; font-size:10px; font-weight:400;}
section.history .twitter_wrap .more-info img{width:12px; height:12px; margin-right:10px;}

section.history .rating.green .number,
section.history .rating.green .trust{background-color:#42B860;}
section.history .rating.yellow .number,
section.history .rating.yellow .trust{background-color:yellow;}
section.history .rating.orange .number,
section.history .rating.orange .trust{background-color:orange;}
section.history .rating.red .number,
section.history .rating.red .trust{background-color:#FF0000;}

/* FAQ ========================================== */
section.faq{display:flex; flex-direction:column;}
section.faq .faq-question{display:flex; justify-content:space-between; align-items:center; width:100%; padding:20px 15px; box-sizing:border-box; margin-top:5px; background-color:#657785; border-radius:3px; transition:all 0.25s;}
section.faq .faq-question img{width:13px; height:auto; margin:2px 0 0 10px; transition:all 0.25s;}
section.faq .faq-question.active{border-radius:3px 3px 0 0;}
section.faq .faq-question.active img{transform:scaleY(-1);}
section.faq .faq-question:hover{cursor:pointer;}
section.faq .faq-question span{padding-right:15px; box-sizing:border-box; font-size:15px; font-weight:400;}
section.faq .faq-answer{display:none; float:left; width:100%; padding:0 15px 20px 15px; box-sizing:border-box; background-color:#657785; border-radius:0 0 3px 3px;}
section.faq .faq-answer *{color:#B1BAC2;}
section.faq .faq-answer p,
section.faq .faq-answer li{font-size:12px; line-height:1.75;}
section.faq .faq-answer a{font-size:12px;}

/* Results ========================================== */
section.results{flex-grow:1; display:flex; flex-direction:column; justify-content:center;}
section.results.search .section-text *{color:#36424B !important;}

section.results .tweet_wrap{display:flex;}
section.results .tweet_wrap a.get_tweet{flex:0 0 48px; display:flex; justify-content:center; align-items:center; height:48px; background:#657785; border-radius:3px 0 0 3px; font-size:14px;}
section.results .tweet_wrap a.get_tweet img{width:20px; height:auto;}
section.results .tweet_wrap #tweet_id{display:block; width:100%; padding:10px; color:#879CAE !important; font-size:15px; border:none !important; border-radius:0 3px 3px 0;}
section.results .tweet_wrap #tweet_id.error{border:1px solid red !important;}
section.results .tweet_wrap #tweet_id::-webkit-input-placeholder{color:#879CAE;}
section.results .tweet_wrap #tweet_id::-moz-placeholder{color:#879CAE;}
section.results .tweet_wrap #tweet_id:-ms-input-placeholder{color:#879CAE;}
section.results .tweet_wrap #tweet_id:-moz-placeholder{color:#879CAE;}
section.results .twitter_wrap{overflow:hidden; float:left; width:100%; max-height:122px; padding-bottom:50px; box-sizing:border-box; margin:5px 0; position:relative; transition:all 0.5s;}
section.results .twitter_wrap .twitter-tweet{margin:0 !important;}
section.results .twitter_wrap .slide-button{display:flex; justify-content:center; align-items:center; width:100%; height:50px; background-color:rgba(101,119,133,0.96); border-radius:3px; position:absolute; bottom:0; left:0; transition:all 0.25s;}
section.results .twitter_wrap .slide-button:hover{cursor:pointer;}
section.results .twitter_wrap .slide-button img{width:12px; height:auto; transition:all 0.25s;}
section.results .twitter_wrap.open{max-height:1000px;}
section.results .twitter_wrap.open .twitter-tweet{margin-bottom:5px !important;}
section.results .twitter_wrap.open .slide-button{background-color:#657785;}
section.results .twitter_wrap.open .slide-button img{transform:scaleY(-1);}

section.results input:-webkit-autofill,
section.results input:-webkit-autofill:hover,
section.results input:-webkit-autofill:focus,
section.results input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
/*Change text in autofill textbox*/
section.results input:-webkit-autofill {
    -webkit-text-fill-color: #879CAE !important;
}

section.results .overall-score{display:flex; align-items:center; width:100%; padding:17px 19px; box-sizing:border-box; margin-bottom:5px; background-color:#657785; border-radius:3px;}
section.results .overall-score .section-text{width:100%;}
section.results .overall-score .section-text p,
section.results .overall-score .section-text li,
section.results .overall-score .section-text a{font-size:13px;}
section.results .rating{flex: 0 0 110px; display:flex; flex-direction:column; margin-left:10px;}
section.results .rating .number{display:flex; justify-content:center; width:100%; padding:10px 0; box-sizing:border-box; margin-bottom:2px; font-size:50px; text-align:center; border-radius:3px 3px 0 0;}
section.results .rating .trust{width:100%; padding:5px 15px; box-sizing:border-box; font-size:11px; font-weight:400; text-align:center; border-radius:0 0 3px 3px;}

section.results .waiting_wrap{border-radius:3px; position:relative; top:0; left:0;}
section.results .waiting_wrap *{font-weight:700;}
section.results .waiting_wrap img{border-radius:3px;}
section.results .waiting_wrap .wait_message{width:100%; text-align:center; position:absolute; left:0; top:5px;}
section.results .waiting_wrap .wait_count{width:100%; text-align:center; position:absolute; left:0; top:120px;}

section.results .block{float:left; width:100%; padding:12px 2px 0; box-sizing:border-box; margin-bottom:20px; background-color:#657785; border-radius:3px; position:relative;}
section.results .block .message { padding: 20px;}
section.results .block_source{position:relative;}
section.results .feat-btn{display:flex; justify-content:center; align-items:center; padding:10px 15px; box-sizing:border-box; background-color:#566470; font-size:11px; border-radius:17px; position:absolute; transform:translate(-50%, 50%);  left:50%; z-index:2;}
section.results .feat-btn:hover {cursor:pointer;}
section.results .feat-btn span{color:#B1BAC2; font-weight:500;}
section.results .feat-btn.open span{color:#fff; }
section.results img.plusminus{width:initial; max-width:17px; height:auto; max-height:13px; margin-right:10px;}
section.results img.plusminus:hover{cursor:pointer;}
section.results img.spread-minus{display:none;}
section.results .block_title {text-align:center;}
section.results .block_2 .block_title {display:block; padding:0 12px; text-align:left;}
section.results .graph{display:inline-block; width:100%; box-sizing:border-box; background:none;}
section.results .graph.acitve .mm_block_pos{display:block;}
section.results .graph.non-acitve .mm_block_pos{display:none;}
section.results .slide-toggle{display:flex; align-items:center; opacity:0.5; width:100%; height:auto; padding:15px; box-sizing:border-box; background-color:#5664706B; position:absolute; bottom:0; left:0; transition:all 0.25s;}
section.results .slide-toggle:hover{cursor:pointer;}
section.results .slide-toggle.open{opacity:1;}
section.results .slide-toggle img{width:20px; height:auto; margin-right:10px;}
section.results .slide-toggle span{font-size:11px; font-weight:500;}
section.results .block-info{display:none; float:left; width:100%; padding:20px 17px; box-sizing:border-box; background-color:#5664706B; font-size:17px; font-weight:700; position:relative;}
section.results #spread_graph_info.block-info{display: block; }
section.results .block-info span.slide-out-title{float:left; width:100%; font-weight:500; line-height:1.3;}
section.results .block-info span.slide-out-feature{float:left; width:100%; margin-top:15px; font-size:13px; font-weight:500; line-height:1.3;}
section.results .block-info span.slide-out-feature-desc{float:left; width:100%; font-size:13px; line-height:1.3;}

section.results #spread_graph_info span.slide-out-title{font-size:13px; line-height:1.5; font-weight:400;}

section.results a.history-btn{display:flex; justify-content:center; align-items:center; float:left; width:100%; padding:15px 20px; box-sizing:border-box; background-color:#657785; border-radius:3px; z-index:2;}
section.results a.history-btn span{opacity:0.5; display:flex; justify-content:center; align-items:center;}
section.results a.history-btn span img{width:20px; height:20px; margin-right:10px;}

/* 404 ========================================== */
section.nopage{flex-grow:unset; display:flex; flex-direction:column; justify-content:flex-start; align-items:center; height:100px;}
section.nopage form{width:100%;}
section.nopage .red-block{padding:28px 30px; box-sizing:border-box; color:#fff; background-color:#ff0000; font-size:82px; font-weight:700; border-radius:13px;}
section.nopage .section-title{padding:20px; margin-bottom:40px; font-size:19px; font-weight:500; border-bottom:1px solid #576571;}
section.nopage .helpful-links{float:left; width:100%;}
section.nopage .section-text{float:left; width:100%; margin-bottom:10px; font-size:13px; text-align:center;}
section.nopage .links{display:flex; justify-content:center; align-items:center; float:left; width:100%;}
section.nopage .links a{margin:0 10px; font-size:13px; border-bottom:1px solid #36424B;}

/* Default Page (Text) ========================================== */
section.default .section-text p,
section.default .section-text li,
section.default .section-text a{font-size:12px;}









.refresh{margin:10px;}

.show_hide_password_wrap{position:relative; left:0; top:0;}
.show_hide_password_wrap .show_hide_password{display:block; position:absolute; right:5px; top:5px;}

.message_banner{float:left; width:100%; padding:0 20px; box-sizing:border-box;}
.message_wrap{display:flex; justify-content:center; align-items:center; float:left; width:100%; padding:10px 25px; box-sizing:border-box; margin-top:20px; background-color:#fff; border-radius:8px;}
.message_wrap .icon{font-size:20px;}
.message_wrap .message{padding-left:15px; margin-left:20px; border-left:2px solid rgba(112,112,112,0.3);}
.message_wrap .message *{color:#566470; font-size:10px;}

section.results .message_wrap{margin:10px 0 15px;}



svg text{font-weight:400; letter-spacing:0 !important;}
svg text.main_title{font-weight:500;}
svg text.main_description{font-size:12px; font-weight:300;}
svg text.main_score_label{font-size:13px;}
svg text.bar_score_label {font-size:11px;}
svg text.bar_labels{font-size:13px; font-weight:500;}
svg text.min_max_labels,
svg text.line_graph_labels,
svg text.on_off_label{font-size:11px;}
svg text.toggel_label{font-size:11px; font-weight:500;}